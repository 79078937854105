import Vue from "vue";
import APIUtils from "@/utils/Network/APIUtils";
export const EventBus = new Vue({
    data:{
        tempItem : null,
        action : null,
        itemType : null,
        thisFolder : {folder_id: 'root', folder_name: '/'}
    },
    created() {
        this.$on("bus:cutFolder", function(item, type){
            this.tempItem = item
            this.action = "CUT"
            this.itemType = type
        })
        this.$on("bus:copyFolder", function(item, type){
            this.tempItem = item
            this.action = "COPY"
            this.itemType = type
        })
        this.$on("bus:pasteFolder", function(item) {
            this.tempItem = null
            this.action = null
            this.itemType = null
        })
        this.$on("bus:openFolder", function(folder_id){
            APIUtils.folder_info({folder_id}).then(res=>{
                this.thisFolder = res.body
            })
        })
    },
});
