<template>
  <div id="Context" v-if="isOpen">
    <div id="closeBox" @click="close" @click.right.stop.prevent="close"></div>
    <div id="ContextContainer" :style="{left: pos.x+'px', top:pos.y+'px'}">
      <div class="bg"></div>
      <ul>
        <li
            v-for="(item, idx) in findContextList"
            :class="item.underline && (idx<findContextList.length-1)?'underline':''">
            <p @click="contextAction(item)" >{{item.name}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/utils/EventBus.js";
import $ from 'jquery'
import APIUtils from "@/utils/Network/APIUtils";
export default {
  data(){
    return{
      isOpen: false,
      pos: {
        x: 0,
        y: 0
      },
      copyOrCutItem : null,
      findContextList : null,
      targetItem : null,
      targetType : null,
      contextList: {
        bg:[
          {code: "folderAdd", name: "새로운 폴더"},
          {code: "folderInfo", name: "정보 가져오기", underline : true},
          {code: "paste", name: "붙여넣기"},
        ],
        folder:[
          {code: "folderOpen", name: "열기"},
          {code: "folderAdd", name: "새로운 폴더", underline : true},
          {code: "folderInfo", name: "정보 가져오기"},
          {code: "folderCngName", name: "이름 바꾸기", underline : true},
          {code: "folderCopy", name: "복사하기"},
          {code: "folderCut", name: "잘라내기"},
          {code: "paste", name: "붙여넣기"},
          {code: "folderDelete", name: "삭제", underline : true},
          {code: "shareOption", name: "공유하기"},
          {code: "shareCancel", name: "공유취소"},
        ],
        file:[
          {code: "fileDownload", name: "다운로드", underline : true},
          {code: "fileInfo", name: "정보 가져오기"},
          {code: "fileCngName", name: "이름 바꾸기", underline : true},
          {code: "fileCopy", name: "복사하기"},
          {code: "fileCut", name: "잘라내기"},
          {code: "fileDelete", name: "삭제"}
        ],
      }
    }
  },
  created(){
    let objThis = this
    EventBus.$on("bus:copyOrCut", function({type, item}){
      objThis.copyOrCutItem = item
    })
    EventBus.$on("bus:openContext", function({x, y, type, target, item}){
      objThis.open({x: x, y: y}, type, target, item)
    })
  },
  methods:{
    contextAction(item){
      this.isOpen = false
      this[item.code](item)
    },
    folderOpen(){
      EventBus.$emit("bus:openFolder", this.targetItem.folder_id)
    },
    folderAdd(){
      EventBus.$emit("bus:openAddFolder", {code: this.targetItem.folder_id, name: this.targetItem.folder_name})
    },
    folderInfo(){
      EventBus.$emit("bus:openItemInfo", this.targetItem, this.targetType)
    },
    folderCngName(){
      EventBus.$emit("bus:openChangeName", this.targetItem, this.targetType)
    },
    folderCopy(){
      EventBus.$emit("bus:copyFolder", this.targetItem, this.targetType)
      this.copyOrCutItem = this.targetItem
    },
    folderCut(){
      EventBus.$emit("bus:cutFolder", this.targetItem, this.targetType)
      this.copyOrCutItem = this.targetItem
    },
    folderDelete(){
      if(confirm("해당 폴더를 삭제하시겠습니까?\n\n※ 하위 폴더 및 파일이 모두 삭제됩니다.")){
        APIUtils.folder_delete({folder_id: this.targetItem.folder_id}).then(res=>{
          if(res.resultCode == 0) {
            EventBus.$emit("bus:refreshWindow", this.targetItem.parent || 'root')
          }
        })
      }
    },

    paste(){
      this.copyOrCutItem = null
      if(EventBus.$data.itemType == "folder"){
        console.log(`[${EventBus.$data.action}] ${EventBus.$data.tempItem.folder_name} -> ${this.targetItem.folder_name}`)
      }else if(EventBus.$data.itemType == "file"){
        console.log(`[${EventBus.$data.action}] ${EventBus.$data.tempItem.file_name} -> ${this.targetItem.folder_name}`)
      }
      EventBus.$emit("bus:cutFolder", this.targetItem)
    },

    fileDownload(){
      window.location.assign(APIUtils.DOWN_URL(this.targetItem.file_id));
    },
    fileInfo(){
      EventBus.$emit("bus:openItemInfo", this.targetItem, this.targetType)
    },
    fileCngName(){
      EventBus.$emit("bus:openChangeName", this.targetItem, this.targetType)
    },
    fileCopy(item){console.log("fileCopy")},
    fileCut(item){console.log("fileCut")},
    fileDelete(){
      if(confirm("해당 파일을 삭제하시겠습니까?")){
        APIUtils.file_delete({file_id: this.targetItem.file_id}).then(res=>{
          if(res.resultCode == 0)
            EventBus.$emit("bus:refreshWindow", this.targetItem.parent)
        })
      }
    },
    open(pos, type, target, item){
      this.pos.x = pos.x
      this.pos.y = pos.y
      this.targetItem = type=="bg" ? EventBus.$data.thisFolder : item
      this.targetType = type
      this.findContextList = this.contextList[type].filter(el=>{
        if(type=='folder' && ((item.shared && el.code == "shareOption") || (!item.shared && el.code == "shareCancel"))){
          return false
        }
        if(el.code == "paste" && this.copyOrCutItem == null){
          return false
        }
        return !el.hide
      })
      this.isOpen = true
    },
    close(){
      this.isOpen = false
    }
  }
}
</script>
<style scoped>
#Context{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
}
#closeBox{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background: transparent;
  z-index: 5;
}
#ContextContainer{
  border: 1px solid #807D85;
  box-shadow: 0 0 5px #0005;
  background: #1e232eb8;
  position: absolute;
  z-index: 10;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(3px);
}
#ContextContainer:before{
  content: "";
  position: absolute;

  z-index: -1;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 1px solid #222;
  border-radius: 10px;
}
#ContextContainer ul{
  font-size: 13px;
  z-index: 30;
  color: #fff;
}
#ContextContainer li{
  min-width: 160px;
  margin: 0 6px;
}
#ContextContainer li p{
  border-radius: 6px;
  margin: 4px 0;
  padding: 4px 8px;
  cursor: pointer;
}
#ContextContainer li p:hover{
  background: #4778D9;
}
#ContextContainer li.underline{
  border-bottom: 1px solid #fff5;
  padding-bottom: 2px;
  margin-bottom: 2px;
}
</style>
