export default {
  getPath: function(url, jsonObj) {
    var path = "";
    var params = "";

    if (jsonObj === undefined) {
      path = url;
    } else {
      var count = Object.keys(jsonObj).length;
      var key = Object.keys(jsonObj);
      var value = Object.values(jsonObj);

      for (let i = 0; i < count; i++) {
        if (value[i] !== null && value[i] !== undefined) {
          params += key[i] + "=" + value[i];
        }
        if (value[i] !== null && value[i] !== undefined && i < count - 1) {
          params += "&";
        }
      }
      path = url + "?" + params;
    }
    return path;
  },
  toFormData: function(obj, rootName, ignoreList){
    var formData = new FormData();
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || '';
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + '[' + i + ']');
          }
        } else if (typeof data === 'object' && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === '') {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + '.' + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== 'undefined') {
            formData.append(root, data);
          }
        }
      }
    }

    function ignore(root){
      return Array.isArray(ignoreList)
          && ignoreList.some(function(x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
  }
};
