<template>
  <div>
    <font-awesome-icon :icon="['fas', getChooseIcon().icon]" :style="{color: color || getChooseIcon().color}"/>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFile, faFileWord, faFileVideo, faFileAudio, faFilePdf, faFilePowerpoint, faFileImage, faFileExcel, faFileCode, faFileArchive, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  data(){
    return {
      chooseIcon : null,
      typeList : [
        { // 워드
          type : ["docx", "doc", "dotx", "dot"],
          icon : "file-word",
          color: "#4969bf"
        },
        { // 파워포인트
          type : ["ppt", "pptx", "potx", "pot"],
          icon : "file-powerpoint",
          color: "#ab3434"
        },
        { // 엑셀
          type : ["xlsx", "xls", "xltx", "xlt"],
          icon : "file-excel",
          color: "#358532"
        },
        { // PDF
          type : ["pdf"],
          icon : "file-pdf",
          color: "#912727"
        },
        { // 비디오
          type : ["mp4", "mov", "wmv", "avi", "mkv"],
          icon : "file-video",
          color: "#6d6a70"
        },
        { // 오디오
          type : ["mp3", "wav", "wma"],
          icon : "file-audio",
          color: "#6c7292"
        },
        { // 이미지
          type : ["jpg", "jpeg", "tiff", "png", "gif", "bmp"],
          icon : "file-image",
          color: "#6cae84"
        },
        { // 코드
          type : ["js", "java", "c", "cc", "cpp", "h", "class", "jsp", "php", "cs", "vue", "htm", "html"],
          icon : "file-code",
          color: "#625083"
        },
        { // 압축파일
          type : ["tar", "7z", "dmg", "egg", "rar", "rzip", "xar", "zip"],
          icon : "file-archive",
          color: "#9f6939"
        },
        { // 문서파일
          type : ["txt", "json", "xml"],
          icon : "file-alt",
          color: "#c3a899"
        },
      ]
    }
  },
  props: {
    color : String,
    file_type : String,

  },
  components: {"font-awesome-icon": FontAwesomeIcon},
  created() {
    library.add(faFile, faFileWord, faFileVideo, faFileAudio, faFilePdf, faFilePowerpoint, faFileImage, faFileExcel, faFileCode, faFileArchive, faFileAlt);
  },
  methods:{
    getChooseIcon(){
      const c = this.typeList.filter(el=>el.type.includes(this.file_type))
      return c.length ? c[0] : {icon : "file", color: "#929292", isDefault: true}
    }
  }
};
</script>

<style scoped>
div{
  width:100%;
  height:100%;
  padding-top: 10px;
}
svg{
  display: block;
  width: 100% !important;
  height: 45px !important;
}
</style>
