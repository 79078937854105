<template>
  <div id="app">
    <router-view name="Top" />
    <router-view name="Left" />
    <router-view name="Right" />
    <router-view name="Bottom" />
    <router-view />

    <MyInfo v-if="info"/>
    <AddFolder v-if="info"/>
    <ChangeName v-if="info"/>
    <ItemInfo v-if="info"/>
    <Context />
    <Search v-if="info"/>
    <Chat v-if="info" />
  </div>
</template>
<script>
import {mapState} from "vuex"
import AddFolder from '@/components/item/AddFolder'
import MyInfo from '@/components/MyInfo'
import ItemInfo from '@/components/item/ItemInfo'
import ChangeName from '@/components/item/ChangeName'
import Context from '@/components/Context'
import Search from '@/components/module/Search'
import Chat from '@/components/chat_test'
export default {
  computed: {
    ...mapState({ info: "saveSwc" }),
  },
  components:{
    MyInfo, ItemInfo, AddFolder, Context, ChangeName, Search, Chat
  }
}
</script>

<style>
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");
</style>
