<template>
  <div id="AddFolder" v-if="isOpen">
    <div class="closePopup" @click="close"></div>
    <div class="popup">
      <h1 class="title">폴더추가<button @click="close"><f-icon icon="times"/></button></h1>
      <div class="form">
        <div class="res">
          <f-icon icon="folder" :style="{color: getValue('colors', addData.folder_color)}" class="folder"/>
          <f-icon :icon="getValue('icons', addData.folder_icon)" v-if="addData.folder_icon != '1'" class="icon"/>
        </div>
        <ul>
          <li>
            <span>아이콘</span>
            <ul>
              <li v-for="(icon, i) in icons" class="i_icon" @click="addData.folder_icon = icon.code" :class="addData.folder_icon == icon.code ? 'active' : ''"><f-icon :icon="icon.value"/></li>
            </ul>
          </li>
          <li>
            <span>색상</span>
            <ul>
              <li v-for="(color, i) in colors" class="i_color" @click="addData.folder_color = color.code" :class="addData.folder_color == color.code ? 'active' : ''" :style="{background: color.value}"></li>
            </ul>
          </li>
          <li>
            <span>이름</span>
            <input type="text" v-model="addData.folder_name">
          </li>
          <li>
            <span>상위폴더</span>
            <p>{{parent.name}}</p>
          </li>
        </ul>
        <button class="btn_add" @click="addFolder">추가</button>
      </div>
    </div>
  </div>
</template>
<script>
import APIUtils from "@/utils/Network/APIUtils";
import { EventBus } from "@/utils/EventBus.js";
import {mapGetters} from "vuex"
export default{
  data(){
    return{
      colors: [],
      icons: [],
      addData:{
        folder_color: 1,
        folder_icon: 1,
        folder_name: "",
        parent: null,
      },
      parent : null,
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters({ info: "getSaveInfo" }),
  },
  created(){
    let objThis = this
    this.colors = this.info.theme.FolderColor.map(i=>{return {code : i.code, value: i.val1}})
    this.icons = this.info.theme.FolderIcon.map(i=>{return {code : i.code, value: i.val1}})
    EventBus.$on("bus:openAddFolder", function(parent){
      objThis.open(parent)
    })
  },
  methods:{
    getValue(type, code){
      return this[type].filter(el=>el.code==code)[0].value
    },
    open(parent){
      this.parent = parent
      this.isOpen = true
    },
    close(){
      this.addData = {
        folder_color: this.colors[0].code,
        folder_icon: this.icons[0].code,
        folder_name: "",
        parent: null,
      }
      this.isOpen = false
    },
    addFolder(){
      this.addData.parent = this.parent.code == 'root' ? null : this.parent.code
      APIUtils.folder_insert(this.addData)
      .then(res=>{
        if(res.resultCode == 0)
          EventBus.$emit("bus:refreshWindow", this.parent.code)
          this.close()
      })
    }
  }
}
</script>
<style scoped>
.form{
  width: 300px;
  padding: 20px;
}
.res{
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  position: relative;
}
.res .folder{
  width:100%;
  height:100%;
}
.res .icon{
  position: absolute;
  left: 50%;
  top: calc(50% + 6px);
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  color: #0006;
}
.form>ul>li{
  font-size: 13px;
  height: 24px;
  margin-top: 20px;
  line-height: 24px;
}
.form>ul>li>*{
  display:inline-block;
}
.form>ul>li>ul,.form>ul>li>input,.form>ul>li>select{
  float:right;
  width: 200px;
}
.form>ul>li>ul{
  display: flex;
  justify-content: space-between;
}
.form>ul>li>input,.form>ul>li>select{
  height: 24px;
}
.btn_add{
  display:block;
  margin: 20px auto 0;
  width: 80px;
  background: #14adea;
  color: #fff;
  border: 0;
  border-radius: 2px;
  height: 30px;
}
</style>
