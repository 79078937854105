import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import io from 'socket.io-client';

import fIcon from "@/components/icon/FIcon"
import fileIcon from "@/components/icon/FileIcon"
import folderIcon from "@/components/icon/FolderIcon"
import profile_default from "@/assets/image/profile.png"

const socket = io('http://localhost:8888');
Vue.prototype.$socket = socket
Vue.prototype.$pd = profile_default
Vue.component("fIcon", fIcon);
Vue.component("fileIcon", fileIcon);
Vue.component("folderIcon", folderIcon);
Vue.config.productionTip = false;

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
