import Vue from "vue";
import Vuex from "vuex";
import api from "@/utils/Network/api"
import APIUtils from "@/utils/Network/APIUtils.js";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    saveSwc : false,
    myData: null,
    folderList: null,
    folderFlat: null,
    chatRoomList: null,
    themeData : null,
  },
  mutations: {
    SET_MY_DATA(state, myData) {
      if (myData) {
        state.myData = myData;
      }
    },
    SET_SAVE_SWC(state, saveInfo){
      if(saveInfo){
        state.saveSwc = saveInfo
      }
    },
    SET_FOLDER_DATA(state, folderData){
      if(folderData){
        state.folderList = folderData.folderList
        state.folderFlat = folderData.folderFlat
      }
    },
    ADD_FOLDER_ITEM(state, folder){
      let toTree = function(arr, root) {
        let temp = arr.filter((item) => {
          return item.parent == root;
        });
        temp.forEach((i) => {
          i.children = toTree(arr, i.folder_id);
        });
        return temp;
      };
      state.folderFlat.push(folder)
      state.folderList = toTree(state.folderFlat, undefined)
    },
    SET_CHAT_DATA(state, chatData){
      if(chatData){
        state.chatRoomList = chatData
      }
    },
    SET_THEME_DATA(state, themeData){
      if(themeData){
        state.themeData = themeData
      }
    },
    SET_ACCESS_TOKEN(state, accessToken) {
      if (accessToken) {
        api.defaults.headers.common.Authorization = `${accessToken}`;
        sessionStorage.setItem("kaloudToken", accessToken);
      }
    },
    DEL_SAVE_DATA(state) {
      state.myData = null;
      state.folderList = [];
      state.chatRoomList = [];
      state.saveSwc = false;
      delete api.defaults.headers.common.Authorization;
      sessionStorage.removeItem("kaloudToken");
    },
  },
  getters: {
    getSaveInfo(state) {
      return {
        my : state.myData,
        folder: state.folderList,
        chat : state.chatRoomList,
        theme : state.themeData,
      }
    }
  },
  actions: {
    login({ commit }, params) {
      return APIUtils.user_login(params)
        .then((res) => {
          const accessToken = res.body;
          if (accessToken) {
            commit('SET_ACCESS_TOKEN', accessToken);
            router.push({ name: "Loading" });
          } else {
            router.push({ name: "Login" });
          }
        })
        .catch((res) => alert(res.desc));
    },
    logout({ commit }) {
      commit('DEL_SAVE_DATA');
      router.push({ name: "Login" });
    },
    addFolder({ commit }, item){
      commit('ADD_FOLDER_ITEM', item)
    }
  },
  modules: {},
});
