import api from "@/utils/Network/api.js";
import businessUtils from "@/utils/Network/BusinessUtils.js";
import error from "@/utils/Network/ErrorTypeUtils.js";

export default {
    //common
    common_theme: function(params){
        return methods.POST("/common/baseTheme", params)
    },

    //user
    user_login: function(params) {
        return methods.POST("/user/login", params);
    },
    user_insert: function(params) {
        return methods.POST("/user/insert", params);
    },
    user_update: function(params) {
        return methods.POST("/user/update", params);
    },
    user_info: function(params) {
        return methods.POST("/user/info", params);
    },
    user_my_info: function(params) {
        return methods.POST("/user/my", params);
    },
    user_get_data: function(params) {
        return methods.POST("/user/getData", params)
    },
    user_checkId: function(params) {
        return methods.POST("/user/checkUserId", params);
    },
    user_findId: function(params) {
        return methods.POST("/user/findUserId", params);
    },
    user_findPwd: function(params) {
        return methods.POST("/user/findUserPwd", params);
    },
    //window
    window_list : function(params){
      return methods.POST("/window/list", params)
    },

    //folder
    folder_info : function(params){
        return methods.POST("/folder/info", params)
    },
    folder_list : function(params){
        return methods.POST("/folder/list", params)
    },
    folder_insert: function(params){
        return methods.POST("/folder/insert", params)
    },
    folder_update : function(params){
        return methods.POST("/folder/update", params)
    },
    folder_move : function(params){
        return methods.POST("/folder/move", params)
    },
    folder_delete : function(params){
        return methods.POST("/folder/delete", params)
    },
    folder_search: function(params){
        return methods.POST("/folder/search", params)
    },

    //file
    DOWN_URL: function(FILE_ID){
        return process.env.VUE_APP_API_BASE_URL + `/file/download/${FILE_ID}`
    },
    file_upload: function(params){
        return methods.POST_FORM("/file/upload", params)
    },
    file_delete: function(params){
        return methods.POST("/file/delete", params)
    },
    file_update: function(params){
        return methods.POST("/file/update", params)
    },
    file_search: function(params){
        return methods.POST("/file/search", params)
    },

    //chat
    room_list: function(params){
        return methods.GET("/chat/roomList", params)
    }
};

const methods = {
    GET: function(URL, PARAMS) {
        var path = businessUtils.getPath(URL, PARAMS);

        return new Promise(function(resolve, reject) {
            api
                .get(path)
                .then((res) => {
                    if (res.data.resultCode === 0) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => error.errorMessage(err.response.status));
        });
    },
    POST: function(URL, PARAMS) {
        return new Promise(function(resolve, reject) {
            api
                .post(URL, PARAMS)
                .then((res) => {
                    if (res.data.resultCode === 0) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => error.errorMessage(err.response.status));
        });
    },
    POST_FORM: function(URL, PARAMS) {
        let formdata = businessUtils.toFormData(PARAMS);
        return new Promise(function(resolve, reject) {
            api
                .post(URL, formdata)
                .then((res) => {
                    if (res.data.resultCode === 0) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => error.errorMessage(err.response.status));
        });
    },
    PUT: function(URL, PARAMS) {
        return new Promise(function(resolve, reject) {
            api
                .put(URL, PARAMS)
                .then((res) => {
                    if (res.data.resultCode === 0) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => error.errorMessage(err.response.status));
        });
    },
    DELETE: function(URL, PARAMS) {
        var path = businessUtils.getPath(URL, PARAMS);

        return new Promise(function(resolve, reject) {
            api
                .delete(path)
                .then((res) => {
                    if (res.data.resultCode === 0) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => error.errorMessage(err.response.status));
        });
    },
}
