<template>
  <div id="Chat" v-if="isOpen">
    <ul></ul>
    <input type="text" v-model="message">
    <button @click="send">전송</button>
  </div>
</template>
<script>
import {EventBus} from "@/utils/EventBus";
import {mapGetters} from "vuex";
export default {
  data(){
    return{
      isOpen: false,
      room_id: null,
      message: ""
    }
  },
  computed: {
    ...mapGetters({ info: "getSaveInfo"}),
  },
  created(){
    let objThis = this
    EventBus.$on("bus:openChat", function(room_id){
      if(objThis.room_id){
        objThis.close()
      }
      objThis.room_id = room_id
      objThis.open()
    })
    EventBus.$on("bus:chatUpdate", function(data){
      console.log(data)
    })
  },
  methods:{
    open(){
      this.isOpen = true
      this.$socket.emit("join", {roomNo: this.room_id})
    },
    close(){
      this.$socket.emit("leave", {roomNo: this.room_id})
      this.isOpen = false
    },
    send(){
      this.$socket.emit("send", {roomNo: this.room_id, message: this.message})
    },
  }
}
</script>
<style scoped>
#Chat{
  display:block;
  position: fixed;
  right:260px;
  bottom:0;
  width:100px;
  height:100px;
  z-index: 10;
}
</style>
