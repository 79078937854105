<template>
  <div id="MyInfo" v-if="isOpen.my">
    <div class="closePopup" @click="close"></div>
    <div class="popup">
      <h1 class="title">내 정보<button @click="close"><f-icon icon="times" /></button></h1>
      <div class="profile">
        <div class="image" :style="{backgroundImage: 'url('+(userInfo.profile ? userInfo.profile : profile_img)+')'}"></div>
        <span>{{info.my.user_id}}</span>
        <button v-if="!modifyMode" class="btnModify" @click="modifyMode = true"><f-icon icon="pen" /></button>

        <input type="file" v-show="false" id="btnFile" @change="changeImage($event)">
        <button v-if="modifyMode" class="btnCamera" @click="openFile"><f-icon icon="camera" /></button>
        <button v-if="modifyMode" class="btnSave" @click="saveProfile"><f-icon icon="save" /></button>
        <button v-if="modifyMode" class="btnClose" @click="closeModify"><f-icon icon="times" /></button>

        <ul class="info">
          <li>이름 <span v-if="!modifyMode">{{info.my.user_name}}</span>
            <input type="text" v-model="userInfo.user_name" v-if="modifyMode">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { EventBus } from "@/utils/EventBus.js";
import {mapGetters} from "vuex"
import APIUtils from "@/utils/Network/APIUtils";
export default{
  data(){
    return{
      profile_img : this.$pd,
      userInfo:{
        user_name: null,
        profile: null
      },
      isOpen:{
        my: false
      },
      modifyMode: false
    }
  },
  computed: {
    ...mapGetters({ info: "getSaveInfo" }),
  },
  mounted() {
    let objThis = this
    EventBus.$on("bus:openMy", function(){
      objThis.isOpen.my = true
    })
    this.userInfo.user_name = this.info.my.user_name
    this.userInfo.profile = this.info.my.profile
  },
  methods:{
    openFile(){
      $("#btnFile").click()
    },
    closeModify(){
      this.userInfo.user_name = this.info.my.user_name
      this.userInfo.profile = this.info.my.profile
      $("#btnFile").val("")
      this.modifyMode = false
    },
    changeImage(e){
      let file = e.target.files[0]
      let imageType = /image.*/
      if(file.type.match(imageType)){
        let reader = new FileReader()
        reader.onload = (e)=>{
            this.userInfo.profile = e.target.result
          }
        reader.readAsDataURL(file)
      }
    },
    saveProfile(){
      APIUtils.user_update(this.userInfo)
      .then(()=>{
        location.reload()
      })
    },
    close(){
      this.modifyMode= false
      this.isOpen.my = false
    }
  }
}
</script>
<style scoped>
div.profile{
  position: relative;
  width: 200px;
  padding-top: 200px;
}
div.profile>.image {
  position: absolute;
  width: 200px;
  height:200px;
  background-size: cover;
  background-position: center center;
  top:0;
  left:0;
  filter: brightness(70%);
}
div.profile>span{
  position: absolute;
  left: 16px;
  top: 12px;
  color: #fff;
  display: block;
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 12px;
}
div.profile>button{
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  width: 34px;
  height: 34px;
  color: #fff;
  border-radius: 24px;
  border: 0;
}
div.profile>button>*{
  width: 40%;
  height: 40%;
}
div.profile button.btnModify{
  right: 20px;
  background: #4778D9;
}
div.profile button.btnClose{
  right: 20px;
  background: #d94747;
}
div.profile button.btnSave{
  right: 58px;
  background: #4778D9;
}
div.profile button.btnCamera{
  left: 20px;
  background: #7B8393;
}

ul.info{
  padding: 24px 16px 16px 16px;
  font-size: 12px;
  color: #546e7a;
}
ul.info li{margin-bottom: 14px; height: 20px; line-height: 20px;}
ul.info li:last-child{margin-bottom: 0;}
ul.info li span{display:inline-block;float:right;width:130px;}
ul.info li input{width: 130px; float: right;height: 100%; padding: 0 4px;border: 0;border-bottom: 1px solid #546e7a;}
</style>
