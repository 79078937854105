<template>
  <font-awesome-icon :icon="[type, icon]" @click="handleClick"/>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell, faUser, faComment } from "@fortawesome/free-regular-svg-icons";
import { faSearch, faAngleLeft, faAngleRight, faCaretRight,faCaretDown, faCaretUp, faKey, faIdBadge, faCog, faPen, faTimes, faCamera, faPlus, faChevronRight, faChevronDown, faChevronUp, faFolder, faFileAlt, faFilm, faImage, faShareAlt, faMusic, faDiagnoses, faSquare, faSave, faFile} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  props: {
    icon : String,
    type: {
      type: String,
      require: false,
      default: 'fas'
    }
  },
  components: {"font-awesome-icon": FontAwesomeIcon},
  created() {
    library.add(
        faBell,
        faUser,
        faComment,
        faSearch,
        faAngleLeft,
        faAngleRight,
        faCaretUp,
        faChevronUp,
        faCaretRight,
        faCaretDown,
        faPen,
        faTimes,
        faKey,
        faCamera,
        faIdBadge,
        faCog,
        faPlus,
        faChevronRight,
        faChevronDown,
        faFolder,
        faFileAlt,
        faFile,
        faFilm,
        faImage,
        faShareAlt,
        faMusic,
        faDiagnoses,
        faSquare,
        faSave);
  },
  methods:{
    handleClick() {
      if (this.$listeners.click) {
        this.$emit('click');
      }
    }
  }
};
</script>

<style scoped></style>
