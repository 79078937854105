<template>
  <div class="folder_icon">
    <font-awesome-icon icon="folder" :style="{color: chooseColor}" class="folder"/>
    <font-awesome-icon :icon="chooseIcon" class="icon" v-if="chooseIcon"/>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";
export default {
  computed: {
    ...mapGetters({info: "getSaveInfo"}),
  },
  data(){
    return {
      chooseColor : null,
      chooseIcon : null,
    }
  },
  props: {
    color : Number,
    icon : Number,
  },
  components: {"font-awesome-icon": FontAwesomeIcon},
  created() {
    this.chooseColor = this.info.theme.FolderColor.filter(el=>el.code*1 == this.color)[0].val1
    this.chooseIcon = this.icon == 1 ? null : this.info.theme.FolderIcon.filter(el=>el.code*1 == this.icon)[0].val1
  }
};
</script>

<style scoped>
.folder_icon{
  height: 62px;
  text-align: center;
  margin: 0 auto;
  line-height: 100px;
  position: relative;
}
.folder{
  width:100%;
  height:100%;
}
.icon{
  position: absolute;
  left: 50%;
  top: calc(50% + 4px);
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  color: #0006;
}
</style>
