<template>
  <div id="ItemInfo" v-if="isOpen">
    <div class="closePopup" @click="close"></div>
    <div class="popup">
      <h1 class="title">{{type=="folder" ? item.folder_name : item.file_name}} 정보<button @click="close"><f-icon icon="times"/></button></h1>
      <div class="form" v-if="type=='folder'">
        <ul>
          <li v-for="info in Object.keys(item)">
            {{info}} : {{item[info]}}
          </li>
        </ul>
      </div>
      <div class="form" v-if="type=='file'">

      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/utils/EventBus";
import APIUtils from "@/utils/Network/APIUtils";
export default {
  data(){
    return{
      isOpen: false,
      item: null,
      type: null
    }
  },
  created() {
    let objThis = this
    EventBus.$on("bus:openItemInfo", function(item, type){
      objThis.open(item, type)
    })
  },
  methods: {
    open(item, type){
      this.item = item
      this.type = type
      if(type == "folder"){
        APIUtils.folder_info({folder_id : item.folder_id}).then(res=>{
          this.item = res.body
        })
      }else if(type == "file"){

      }
      this.type = type
      this.isOpen = true
    },
    close(){
      this.item = null
      this.type = null
      this.isOpen = false
    },
  }
};
</script>

<style scoped>
.form{
  padding: 10px 20px;
  min-width: 400px;
}
</style>
