<template>
  <div id="Search" class="layout" v-if="isOpen">
    <div class="status">
      검색 : <p>{{keyword}}</p> <i @click="close"><f-icon icon="times" /></i>
    </div>
    <div v-if="!listStyle" class="grid">
      <ul>
        <li class="folderItem" v-for="item in folderList">
          <div class="icon" @dblclick="openFolder(item)">
            <folder-icon :icon="item.folder_icon" :color="item.folder_color" />
            <i class="shared" v-if="item.shared">
              <f-icon icon="share-alt"/>
              <h5>공유중인 폴더입니다.</h5>
            </i>
          </div>
          <span>{{ item.folder_name }}</span>
        </li>
        <li class="fileItem" v-for="item in fileList">
          <div class="icon">
            <file-icon :file_type="item.file_type"/>
          </div>
          <span>{{ item.ori_name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/utils/EventBus";
import APIUtils from "@/utils/Network/APIUtils";

export default {
  data() {
    return {
      isOpen: false,
      fileList: [],
      folderList: [],
      keyword: "",
      listStyle: false,
    }
  },
  created() {
    let objThis = this
    EventBus.$on("bus:openSearch", function (keyword) {
      objThis.getData(keyword)
    })
    document.addEventListener('keydown', function(e){
      if(e.code == "Escape" && objThis.isOpen){
        objThis.close()
      }
    })
  },
  methods: {
    getData(keyword){
      this.keyword = keyword
      APIUtils.file_search({ori_name: keyword}).then(resFile=>{
        APIUtils.folder_search({folder_name: keyword}).then(resFolder=>{
          console.log(resFolder)
          this.fileList = resFile.body
          this.folderList = resFolder.body
          this.isOpen = true
        })
      })
    },
    openFolder(){},
    close(){
      this.fileList = []
      this.folderList = []
      this.keyword = ""
      this.isOpen = false
    }
  }
};
</script>

<style scoped>
#Search{
  z-index: 1;
}
.status{
  font-size: 13px;
  width: 100%;
  height: 30px;
  background: #fff;
  padding: 0 20px;
  border-top: 1px solid #7B8393;
  line-height: 30px;
}
.status p{
  display: inline;
}
.status i{
  display: inline-block;
  width:18px;
  height:18px;
  background: #7B8393;
  border-radius: 4px;
  line-height: 18px;
  text-align: center;
  margin-left: 10px;
}
.status i>svg{
  width:100%;
  height:70%;
}

</style>
