<template>
  <div id="ChangeName" v-if="isOpen">
    <div class="closePopup" @click="close"></div>
    <div class="popup">
      <h1 class="title">이름 바꾸기<button @click="close"><f-icon icon="times"/></button></h1>
      <div class="form">
        <input type="text" v-model="name" :class="type" autofocus><p v-if="type=='file'">.{{item.file_type}}</p>
        <button class="btn_save" @click="save">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/utils/EventBus";
import APIUtils from "@/utils/Network/APIUtils";

export default {
  data(){
    return{
      isOpen : false,
      name : "",
      item : null,
      type : null
    }
  },
  created() {
    let objThis = this
    EventBus.$on("bus:openChangeName", function(item, type){
      objThis.open(item, type)
    })
  },
  methods:{
    open(item, type){
      this.item = item
      this.type = type
      this.name = type == "folder" ? item.folder_name : item.ori_name.replaceAll(`.${item.file_type}`,'')
      this.isOpen = true
    },
    close(){
      this.item = null
      this.type = null
      this.name = ""
      this.isOpen = false
    },
    save(){
      if(this.type == "folder"){
        APIUtils.folder_update({folder_id: this.item.folder_id, folder_name: this.name}).then(res=>{
          if(res.resultCode == 0){
            EventBus.$emit("bus:refreshWindow", this.item.parent || 'root')
            this.close()
          }
        })
      }else if(this.type == "file"){
        let changeName = this.name + "." +this.item.file_type
        APIUtils.file_update({file_id: this.item.file_id, ori_name: changeName}).then(res=>{
          if(res.resultCode == 0){
            EventBus.$emit("bus:refreshWindow", this.item.parent)
            this.close()
          }
        })
      }
    }
  }
};
</script>

<style scoped>
.form{
  padding: 10px 20px;
}
input[type=text]{
  border: 0;
  border-bottom: 1px solid #546e7a;
  width:100%;
}
input[type=text].file{
  border: 0;
  border-bottom: 1px solid #546e7a;
  width: calc(100% - 30px);
}
input[type=text]:focus{
  outline: none;
}
p{
  display:inline-block;
  width:30px;
}
button.btn_save{
  display: block;
  margin: 10px auto 0;
  width: 80px;
  background: #14adea;
  color: #fff;
  border: 0;
  border-radius: 2px;
  height: 30px;
}
</style>
