import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/loading",
    name: "Loading",
    component: () => import("@/views/Loading.vue")
  },
  {
    path: "/",
    name: "Window",
    components: {
      Top: () => import("@/views/layout/Top.vue"),
      Left: () => import("@/views/layout/Left.vue"),
      default: () => import("@/views/layout/Window.vue"),
      Right: () => import("@/views/layout/Right.vue"),
      Bottom: () => import("@/views/layout/Bottom.vue"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next)=>{
  const sToken = sessionStorage.getItem("kaloudToken")
  const sInfo = store.state.saveSwc
  if(sToken && sInfo){  // 로그인 및 정보 로딩 성공
    return to.name == "Window" ? next() : next("/")
  }
  else if(sToken && !sInfo) {  // 토큰으로 로그인
    return to.name == "Loading" ? next() : next("/loading")
  }
  else if(!sToken && sInfo){  // 잘못된 토큰으로 인한 로그아웃
    store.dispatch("logout")
  }
  else{
    return to.name == "Login" ? next() : next("/login")
  }
})

export default router;

